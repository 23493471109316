import api from '@/utils/api'
import axios from 'axios'

export async function upload(file) {
  try {
    if (file && file.size) {
      const { data: responseSignedUrl } = await api.get('uploads/signed-url', {
        params: {
          fileType: file.type
        }
      })

      if (responseSignedUrl.error) {
        return responseSignedUrl
      }

      const { url: signedUrl } = responseSignedUrl
      const url = signedUrl.split('?')[0]

      const options = {
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': file.type,
          'Content-Disposition': `attachment; filename=${file.name}`
        }
      }

      await axios.put(signedUrl, file, options)
      return url
    }
    return null
  } catch (e) {
    console.error(e)
  }
}
