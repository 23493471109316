<template>
  <div class='platform-contracts mt-3 p-3 mb-5'>
    <ModalConfirmAction
      variant='danger'
      refModal='resePrivacyPolicyConfirmation'
      idModal='resePrivacyPolicyConfirmation'
      :loading='loadingReset'
      :description='descriptionReset'
      :title='titleReset'
      @confirmed='resetPrivacyPolicyConfirmation'
    />

    <ModalConfirmAction
      variant='danger'
      refModal='resetTermsConfirmation'
      idModal='resetTermsConfirmation'
      :loading='loadingReset'
      :description='descriptionReset'
      :title='titleReset'
      @confirmed='resetTermsConfirmation'
    />

    <b-overlay
      :show='loadingGet'
      rounded
      opacity='0.6'
      ariant='info'
      spinner-variant='info'
      class='d-inline-block w-100'
    >
      <h1>
        Gerenciar Contratos da plataforma
      </h1>

      <hr class='mt-4 mb-1' />

      <section class='mt-2 '>
        <label><strong>Aceitar termos de uso e política de privacidade</strong></label>
        <section class='d-flex align-items-center'>
          <b-button
            variant='outline-info'
            class='mr-2'
            @click='showModalTermsConfirmation()'
          >
            Obrigar os usuários aceitarem os termos de uso.
          </b-button>

          <b-button
            variant='outline-info'
            @click='showModalPrivacyPolicyConfirmation()'
          >
            Obrigar os usuários aceitarem a política de privacidade.
          </b-button>
        </section>
      </section>

      <section class='mt-5'>
        <section>
          <label for='filesAdhesionContractUrl'><strong>Contrato de adesão</strong></label>
          <b-form-file
            name='filesAdhesionContractUrl'
            id='filesAdhesionContractUrl'
            v-model='filesAdhesionContractUrl'
            placeholder='Selecione o arquivo de contrato de adesão'
            accept='.pdf'
            browse-text='Selecione o arquivo'
          >
          </b-form-file>
        </section>
        <section class='mt-3' v-if='term && term._id'>
          <p>
            <a
              :href='term.adhesionContractUrl'
            >
              <strong class='label-form'>Clique para ver o arquivo {{ term.adhesionContractName }} anexado no dia
                {{ format(new Date(term.adhesionContractDate), 'dd/MM/yyyy HH:mm:ss') }}</strong>
            </a>
          </p>
        </section>
      </section>


      <section class='mt-5'>
        <section>
          <label for='filesTermsOfUseUrl'><strong>Contrato de Termos de uso</strong></label>
          <b-form-file
            name='filesTermsOfUseUrl'
            id='filesTermsOfUseUrl'
            v-model='filesTermsOfUseUrl'
            placeholder='Selecione o arquivo de termos de uso'
            accept='.pdf'
            browse-text='Selecione o arquivo'
          >
          </b-form-file>
        </section>

        <section class='mt-3' v-if='term && term._id'>
          <p>
            <a
              :href='term.termsOfUseUrl'
            >
              <strong class='label-form'>Clique para ver o arquivo {{ term.termsOfUseName }} anexado no dia
                {{ format(new Date(term.termsOfUseDate), 'dd/MM/yyyy HH:mm:ss') }}</strong>
            </a>
          </p>
        </section>
      </section>


      <section class='mt-5'>
        <section>
          <label for='filesPrivacyPolicyUrl'><strong>Contrato de política de privacidade</strong></label>
          <b-form-file
            name='filesPrivacyPolicyUrl'
            id='filesPrivacyPolicyUrl'
            v-model='filesPrivacyPolicyUrl'
            placeholder='Selecione o arquivo de política de privacidade'
            accept='.pdf'
            browse-text='Selecione o arquivo'
          >
          </b-form-file>
        </section>

        <section class='mt-3' v-if='term && term._id'>
          <p>
            <a
              :href='term.privacyPolicyUrl'
            >
              <strong class='label-form'>Clique para ver o arquivo {{ term.privacyPolicyName }} anexado no dia
                {{ format(new Date(term.privacyPolicyDate), 'dd/MM/yyyy HH:mm:ss') }}</strong>
            </a>
          </p>
        </section>
      </section>

      <section class='mt-5 d-flex align-items-center justify-content-between w-100'>
        <b-button
          variant='danger'
          class='w-50 mr-2'
          @click="$router.push('/web')"
        >
          Cancelar
        </b-button>

        <b-overlay
          :show='loading'
          rounded
          opacity='0.6'
          spinner-small
          spinner-variant='primary'
          class='w-50 '
        >
          <b-button
            variant='success'
            class='w-100'
            @click='submit()'
          >
            Atualizar
          </b-button>
        </b-overlay>
      </section>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { upload } from '@/utils/upload'
import { format } from 'date-fns'
import ModalConfirmAction from '@/components/ModalConfirmAction.vue'

export default {
  name: 'PlatformContracts',
  components: { ModalConfirmAction },
  data() {
    return {
      loadingReset: false,
      descriptionReset: '',
      titleReset: '',
      loadingGet: false,
      loading: false,
      filesAdhesionContractUrl: null,
      filesTermsOfUseUrl: null,
      filesPrivacyPolicyUrl: null,
      term: {
        _id: null,
        adhesionContractUrl: null,
        adhesionContractName: null,
        adhesionContractDate: null,
        termsOfUseUrl: null,
        termsOfUseName: null,
        termsOfUseDate: null,
        privacyPolicyUrl: null,
        privacyPolicyName: null,
        privacyPolicyDate: null
      }
    }
  },
  created() {
    const breadcrumb = {
      tab: '',
      page: 'Gerenciar Contratos da plataforma',
      subPage: {
        name: 'Gerenciar Contratos da plataforma',
        active: true
      },
      tabs: []
    }
    this.setBreadcrumb(breadcrumb)
  },
  async mounted() {
    this.reset()
    await this.getTem()
  },
  computed: {
    user() {
      return this.$store.getters['user/current']
    }
  },
  methods: {
    format,
    ...mapActions('term', {
      handleCreate: 'handleCreate',
      handleGet: 'handleGet',
      handleUpdade: 'handleUpdade'
    }),
    ...mapActions('breadcrumb', {
      setBreadcrumb: 'setBreadcrumb'
    }),
    ...mapActions('user', {
      handleUpdatePrivacyPolicy: 'handleUpdatePrivacyPolicy',
      handleUpdateTermsOfUse: 'handleUpdateTermsOfUse'
    }),
    async getTem() {
      try {
        this.loadingGet = true
        const term = await this.handleGet()
        if (term) {
          this.term = term
        }
        this.loadingGet = false
      } catch (e) {

      }
    },

    reset() {
      this.filesAdhesionContractUrl = null
      this.filesTermsOfUseUrl = null
      this.filesPrivacyPolicyUrl = null
      this.term = {
        _id: null,
        adhesionContractUrl: null,
        adhesionContractName: null,
        adhesionContractDate: null,
        termsOfUseUrl: null,
        termsOfUseName: null,
        termsOfUseDate: null,
        privacyPolicyUrl: null,
        privacyPolicyName: null,
        privacyPolicyDate: null
      }
    },

    async submit() {
      try {
        let response = {}
        this.loading = true
        if (this.term._id) {
          if (this.filesAdhesionContractUrl) {
            this.term.adhesionContractUrl = await upload(this.filesAdhesionContractUrl)
            this.term.adhesionContractName = this.filesAdhesionContractUrl.name
          }
          if (this.filesTermsOfUseUrl) {
            this.term.termsOfUseUrl = await upload(this.filesTermsOfUseUrl)
            this.term.termsOfUseName = this.filesTermsOfUseUrl.name
          }
          if (this.filesPrivacyPolicyUrl) {
            this.term.privacyPolicyUrl = await upload(this.filesPrivacyPolicyUrl)
            this.term.privacyPolicyName = this.filesPrivacyPolicyUrl.name
          }

          response = await this.handleUpdade(this.term)
        } else {
          this.term.adhesionContractUrl = await upload(this.filesAdhesionContractUrl)
          this.term.adhesionContractName = this.filesAdhesionContractUrl.name
          this.term.termsOfUseUrl = await upload(this.filesTermsOfUseUrl)
          this.term.termsOfUseName = this.filesTermsOfUseUrl.name
          this.term.privacyPolicyUrl = await upload(this.filesPrivacyPolicyUrl)
          this.term.privacyPolicyName = this.filesPrivacyPolicyUrl.name
          response = await this.handleCreate(this.term)
        }
        const { data, status } = response
        let message = data.message
        let variant = 'danger'
        if (status === 200 || status === 201) {
          message = data.message
          variant = 'success'
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.loading = false
        this.reset()
        await this.getTem()
      } catch (e) {
        console.error(e)
        this.$bvToast.toast('Não foi possível executar essa ação, verifique os dados e tente novamente.', {
          title: 'Atenção',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.loading = false
      }
    },

    showModalTermsConfirmation() {
      try {
        this.$bvModal.show('resetTermsConfirmation')
        this.titleReset = `Obrigar os usuários aceitarem os termos de uso.`
        this.descriptionReset = `Essa ação vai obrigar os usuários aceitarem os termos de uso. <br/> Deseja realmente executar essa ação?`
      } catch (e) {

      }
    },
    async resetTermsConfirmation() {
      try {
        this.loadingReset = true
        const { data, status } = await this.handleUpdateTermsOfUse()
        let message = data.message
        let variant = 'danger'
        if (status === 200 || status === 201) {
          message = data.message
          variant = 'success'
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.$bvModal.hide('resetTermsConfirmation')
        this.loadingReset = false
      } catch (e) {
        this.$bvToast.toast('Não foi possível executar essa ação, verifique os dados e tente novamente.', {
          title: 'Atenção',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.$bvModal.hide('resetTermsConfirmation')
        this.loadingReset = false
      }
    },

    showModalPrivacyPolicyConfirmation() {
      try {
        this.$bvModal.show('resePrivacyPolicyConfirmation')
        this.titleReset = `Obrigar os usuários aceitarem a política de privacidade.`
        this.descriptionReset = `Essa ação vai obrigar os usuários aceitarem a política de privacidade. <br/> Deseja realmente executar essa ação?`
      } catch (e) {

      }
    },
    async resetPrivacyPolicyConfirmation() {
      try {
        this.loadingReset = true
        const { data, status } = await this.handleUpdatePrivacyPolicy()
        let message = data.message
        let variant = 'danger'
        if (status === 200 || status === 201) {
          message = data.message
          variant = 'success'
        }
        this.$bvToast.toast(message, {
          title: 'Atenção',
          variant: variant,
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.loadingReset = false
        this.$bvModal.hide('resePrivacyPolicyConfirmation')
      } catch (e) {
        this.$bvToast.toast('Não foi possível executar essa ação, verifique os dados e tente novamente.', {
          title: 'Atenção',
          variant: 'danger',
          toaster: 'b-toaster-top-center',
          autoHideDelay: 5000
        })
        this.$bvModal.hide('resePrivacyPolicyConfirmation')
        this.loadingReset = false
      }
    }


  }
}
</script>

<style lang='scss' scoped>
.platform-contracts {
  background: #FFFFFF !important;
  border: 1px solid #C0C0C0;

  h1 {
    font-size: 20px;
    font-weight: 700;
    line-height: 24.38px;
    text-align: left;
    color: #262626;
  }

  hr {
    background: #C0C0C0;
  }

  .label-form {
    font-size: 16px;
    font-weight: 600;
    text-align: left;
    color: var(--navy-blue);
    text-decoration: underline;
  }
}
</style>
